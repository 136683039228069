import {SignupHeader} from "./framer"

import { Spacer } from "@geist-ui/core";
import { Input, PrimaryButton } from "../../ui";
import { useState } from "react";

const WrappedInput = ({ text, name, onChange, value, password, type, placeholder, autocomplete, onComplete, throwError }) => {

    const [hasError, setHasError] = useState(false)

    const hijacker = e => {
        setHasError(e.target.value === "")
        onChange(name, e.target.value)
    }

    return <div>
        {text}
        <Spacer h={0.5} />
        <Input throwError={throwError} onEnter={onComplete} autoComplete={autocomplete} type={password ? "password" : type} style={{ border: hasError ? "1px solid red" : null, width: "100%" }} name={name} value={value} onChange={hijacker} placeholder={placeholder} />
    </div>
}


export { SignupHeader, WrappedInput };