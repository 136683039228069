import { WrappedInput } from "../../components/auth"
import { Grid, Spacer } from "@geist-ui/core"
import { PrimaryButton } from "../../ui"

const PasswordStep = ({ onChange, data, onComplete, res }) => {
    return <Grid direction="column">
        <WrappedInput onComplete={onComplete} password value={data?.password} onChange={onChange} name="password" text='Create Password' />
        <Spacer />
        <WrappedInput password onComplete={onComplete} value={data?.confirmedPassword} onChange={onChange} text='Confirm Password' name="confirmedPassword" />
        <Spacer h={2} />
        <PrimaryButton style={{ background: "black" }} fullWidth onClick={onComplete}>Next</PrimaryButton>
    </Grid>
}

const onComplete = (setStep) => { setStep("organisationName") }

const passwordStep = {
    View: PasswordStep,
    title: "Create a Password",
    subtitle: "Your password must be more than 8 characters, include a number and a capital",
    onComplete,
    isValid: (data) => validator.validate(data.password) && data.password === data.confirmedPassword
}

export default passwordStep