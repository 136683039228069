import {Grid} from "@geist-ui/core"
import { Card } from "../../ui"
import styles from './wrapper.module.css'

const AuthWrapper = ({children}) => {
    return <Grid.Container className={styles.page} justify="center">
        <Grid xs={24} sm={12} direction='column' alignContent='stretch' justify="center" alignItems="center">
            <Card className={styles.card}>
                {children}
            </Card>
        </Grid>
    </Grid.Container>
}

export default AuthWrapper